import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import {notification} from "ant-design-vue"

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../components/NF404.vue')
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/Main.vue'),
    meta: {
      loginRequire : true
    },
    children: [{
      path: 'welcome',
      component: () => import('../views/main/Welcome.vue'),
    },{
      path:'passenger',
      component: () => import('../views/main/Passenger.vue')
    },{
      path:'ticket',
      component: () => import('../views/main/Ticket.vue')
    },{
      path:'order',
      component: () => import('../views/main/Order.vue')
    }, {
      path: 'my-ticket',
      component: () => import('../views/main/MyTicket.vue')
    }, {
      path: 'seat',
      component: () => import('../views/main/Seat.vue')
    }]
  },
  {
    path:'',
    redirect: '/login'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由登录拦截
router.beforeEach((to, from, next) => {
  // 要不要对meta.loginRequire属性做监控拦截
  if (to.matched.some(function (item) {
    console.log(item, "是否需要登录校验：", item.meta.loginRequire || false);
    return item.meta.loginRequire
  })) {
    const _member = store.state.member;
    console.log("页面登录校验开始：", _member);
    if (!_member.token) {
      console.log("用户未登录或登录超时！");
      notification.error({ description: "未登录或登录超时" });
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => { if (to.matched.length === 0) { next('/404') } else { next() } })

export default router
